import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Label from "../../components/label";

export default class RecoveryConfirmationPage extends Component {
  componentDidMount() {
    if (!this.props.location.query || !this.props.location.query.alowed) {
      this.props.history.push("/login");
    }
  }
  render() {
    return (
      <div className="container container-middle container-small">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title">
              <Label name="recoveryConfirmationPage.title" />
            </h3>
            <h4 className="title">
              <Label name="recoveryConfirmationPage.recoveryEmailSent" />
            </h4>
            <p>
              <Label name="recoveryConfirmationPage.recoveryEmailInstruction" />
            </p>
            <div className="description">
              <Label name="recoveryConfirmationPage.didNotReceive" />
              <ol>
                <li>
                  <Label name="recoveryConfirmationPage.checkSpam" />
                </li>
                <li>
                  <Label type="texts" name="recoveryConfirmationPage.contactUsByEmail"/>
                  <Label type="settings" name="contactEmail" />
                </li>
              </ol>
            </div>
            <Link to="/login" className="btn">
              <Label name="recoveryConfirmationPage.linkToSigningPage" />
            </Link>
            <hr className="distance" />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
