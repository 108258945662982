import React, { Component } from "react";
import { AuthService } from "../../services/auth.service";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

const usernameParam = "username";
const tokenParam = "activationToken";

export default class activatePage extends Component {
  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const parsed = queryString.parse(search);
    if (!parsed[usernameParam] || !parsed[tokenParam]) {
      this.goToErrorPage(CultureService.getLabel("texts", "activatePage.error"));
      return;
    }
    AuthService.activate(
      parsed[usernameParam],
      parsed[tokenParam],
      this.goToErrorPage
    );
  }

  goToErrorPage = message => {
    this.props.history.push({
      pathname: "/error",
      query: { error: message }
    });
  };

  render() {
    return (
      <div className="container container-middle container-small">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title"><Label name="activatePage.title" /></h3>
            <h4 className="title mb-big"><Label name="activatePage.subTitle" /></h4>
            <Link to="/login" className="btn">
              <Label name="activatePage.link" />
            </Link>
            <hr className="distance" />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
