import React, { Component } from "react";
import { AuthService } from "../../services/auth.service.js";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

const usernameType = "username";
const passwordType = "password";

const initialState = {
  username: {
    value: "",
    errors: {
      required: false
    },
    invalid: false
  },
  password: {
    value: "",
    errors: {
      required: false
    },
    invalid: false
  },
  rememberMe: false,
  errorMessages: [],
  disabledLogin: false
};

export default class LoginPage extends Component {
  returnPath = "/";
  form = React.createRef();
  state = {
    ...initialState
  };
  wrapper = React.createRef();

  componentDidMount() {
    this.setEnterKeyListen(true);
    AuthService.loadDemoAccounts();
  }
  componentWillUnmount() {
    this.setEnterKeyListen();
  }
  setEnterKeyListen(status) {
    const form = this.form.current;
    if (form) {
      if (status) {
        form.addEventListener("keyup", this.handleKey);
        return;
      }
      form.removeEventListener("keyup", this.handleKey);
    }
  }
  handleKey = e => {
    //console.log('clicked', e.key, e.keyCode)
    if (e.key === "Enter") {
      this.login();
    }
  };
  login = () => {
    console.log("login disabled is ", this.state.disabledLogin);
    if (!this.validate()) {
      console.log("validate false");
      return;
    }
    this.setState({
      disabledLogin: true
    });
    AuthService.login(
      this.state.username.value,
      this.state.password.value,
      this.state.rememberMe,
      this.onError
    );
  };
  onError = data => {
    const wrapper = this.wrapper.current;
    if (wrapper) {
      wrapper.scrollTo(0, 0);
    }
    this.setState({
      errorMessages: [data.error],
      disabledLogin: false
    });
    return;
  };
  validate = () => {
    let valid = true;
    const userNameVal = this.state.username.value;
    const passwordVal = this.state.password.value;
    if (!userNameVal) {
      valid = false;
      this.setState(state => ({
        username: {
          ...state.username,
          errors: {
            required: true
          },
          invalid: true
        }
      }));
    }
    if (!passwordVal) {
      valid = false;
      this.setState(state => ({
        password: {
          ...state.password,
          errors: {
            required: true
          },
          invalid: true
        }
      }));
    }
    return valid;
  };
  loginAsDemo = () => {
    AuthService.loginAsDemo(data => {
      console.log(data);
    });
  };
  handleChange(e, type) {
    const inputVal = e.target.value;
    this.setState({
      [type]: {
        ...initialState[type],
        value: inputVal
      }
    });
  }
  handleCheckBoxChange() {
    this.setState({
      rememberMe: !this.state.rememberMe
    });
  }
  render() {
    const hasError = this.state.errorMessages.map((errorMsg, index) => (
      <span key={errorMsg + index}>{errorMsg}</span>
    ));
    return (
      <div className="container container-small container-middle">
        <div ref={this.wrapper}>
          {this.state.disabledLogin && <div className="loaderSpinner" />}
          <div
            className="contentWrapper"
            style={{
              visibility: this.state.disabledLogin ? "hidden" : "visible"
            }}
          >
            <Header />
            <div className="form" ref={this.form}>
              <h3 className="title"><Label name="loginPage.signInText" /> </h3>{/*{CultureService.getLabel("settings", "brandName") || 'portal'}</h3>*/}
              {!!hasError.length && (
                <p className="error text-center">{hasError}</p>
              )}

              <div
                className={
                  this.state.username.invalid
                    ? "form-group error"
                    : "form-group"
                }
              >
                <label htmlFor="username">
                  <Label name="loginPage.userName" />
                </label>
                <input
                  type="text"
                  id="username"
                  value={this.state.username.value}
                  onChange={e => this.handleChange(e, usernameType)}
                  htmlFor="username"
                />
                {this.state.username.invalid && (
                  <div>
                    {this.state.username.errors.required && (
                      <span><Label name="loginPage.userNameRequired" /></span>
                    )}
                    {this.state.username.errors.demoUsernameBlocked && (
                      <span>
                        <Label name="loginPage.demoUserBlockedStart" />{" "}
                        <Link to="/register"><Label name="loginPage.demoUserBlockedLink" /></Link><Label name="loginPage.demoUserBlockedEnd" />
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div
                className={
                  this.state.password.invalid
                    ? "form-group error"
                    : "form-group"
                }
              >
                <label htmlFor="password">
                  <Label name="loginPage.password" />
                </label>
                <input
                  type="password"
                  id="password"
                  value={this.state.password.value}
                  onChange={e => this.handleChange(e, passwordType)}
                />
                {this.state.password.invalid && (
                  <div>
                    {this.state.password.errors.required && (
                      <span><Label name="loginPage.passwordRequired" /></span>
                    )}
                  </div>
                )}
              </div>
              <div className="form-group">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  value={this.state.rememberMe}
                  onChange={() => this.handleCheckBoxChange()}
                />
                <label for="rememberMe">
                  <Label name="loginPage.rememberMe" />
                </label>
              </div>
              {/*appTitle !== fxType &&
                                <p className="rememberMe">
                                    <input className="checkbox" type="checkbox" onSwitch={() => this.handleCheckBoxChange()} />
                                    <span>Remember me</span>
                                </p>
                            */}
              <div className="mb-3"></div>
              <button
                className="btn"
                onClick={!this.state.disabledLogin && this.login}
              >
                <span><Label name="loginPage.signIn" /></span>
              </button>
              <p className="text-center">
                <Link to="/recovery" className="link hoverable">
                  <Label name="loginPage.forgotPassword" />
                </Link>
              </p>
              <Link to="/register" className="btn btn-secondary">
                <Label name="loginPage.createNewAccount" />
              </Link>
              {CultureService.getLabel("settings", "demoLink") ?
                (<p className="text-center">
                  <p className="link hoverable" onClick={this.loginAsDemo}>
                    <Label name="loginPage.tryDemo" />
                  </p>
                </p>
                ) : <div style={{ marginTop: '40px' }}></div>}
              <hr />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
