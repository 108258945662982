import React from "react";
import ReactCountryFlag from "react-country-flag";
import CultureService from "../services/culture.service";
import Label from "./label";
import SelectBox from "./selectBox";

const Header = () => {
  const culture = {
    items: window.viewData.options.map((option) => ({
      value: option.value,
      content: (
        <React.Fragment>
          <ReactCountryFlag
            countryCode={option.flag}
            svg
            style={{ fontSize: "1.5em" }}
          />
          <span>{option.uiText}</span>
        </React.Fragment>
      ),
    })),
    onChange: (item) => {
      CultureService.culture = item.value;
    },
  };

  return (
    <div className="text-center">
      <SelectBox
        className="culture__select-box"
        align="right"
        active={culture.items.find(
          (item) => item.value === CultureService.culture
        )}
        items={culture.items}
        onChange={(item) => culture.onChange(item)}
      />
      {CultureService.getLabel("settings", "iconShown") &&
        CultureService.getLabel("settings", "iconUrl") && (
          <img
            src={CultureService.getLabel("settings", "iconUrl")}
            alt={CultureService.getLabel("settings", "iconAlt")}
            className="brand-icon"
          />
        )}
      {/* <h2 className="brand-title"><Label type="settings" name="brandName" /></h2> */}
      {CultureService.getLabel("settings", "brandShown") &&
        CultureService.getLabel("settings", "leftLogoShown") && (
          <div>
            <h2 className="brand-title">
              <Label type="settings" name="brandName" />
            </h2>
            <p className="brand-title-description">
              <Label type="settings" name="poweredBy" />
              <img
                src={CultureService.getLabel("settings", "leftLogo")}
                alt={CultureService.getLabel("settings", "leftLogoAlt")}
                className="brandSmallLogo"
                style={{
                  cursor: CultureService.getLabel("settings", "leftLogoGoToUrl")
                    ? "pointer"
                    : "auto",
                }}
              />
            </p>
          </div>
        )}
    </div>
  );
};

export default Header;
