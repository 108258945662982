import React from "react";
import CultureService from "../services/culture.service";

class Label extends React.Component {
  type;
  path;

  constructor(props) {
    super(props);
    this.type = props.type || "texts";
    this.path = props.name;
    this.state = { label: "" };
  }

  componentDidMount() {
    this._subscribe = CultureService.subscribeCultureChange(() => this.onChange());
    this.onChange();
  }

  componentWillUnmount() {
    CultureService.unsubscribeCultureChange(this._subscribe);
  }

  render() {
    const match = this.state.label.match(/\$\{.*?\}/);
    if (match) {
      const key = match[0].substring(2, match[0].length - 1);
      const keyParts = key.split(":");
      this.state.label = this.state.label.replace(match[0], CultureService.getLabel(keyParts[0], keyParts[1]));
    }
    return <React.Fragment><span dangerouslySetInnerHTML={{ __html: this.state.label }}></span></React.Fragment>;
  }

  onChange() {
    const label = CultureService.getLabel(this.type, this.path) || "";
    this.setState((state, props) => ({
      label
    }));
  }
}

export default Label;
