import Cookies from "universal-cookie";

export const CULTURE_COOKIE_NAME = "fluxorm_culture_code";
export const CULTURE_COOKIE_EXPIRES_DAYS = 365;

export default class CultureService {
    static _culture = this.getDefaultCulture();
    static _subscribersIndex = 0;
    static _subscribers = {};

    static get culture() {
        return this._culture;
    }

    static set culture(value) {
        this._culture = value;
        new Cookies().set(CULTURE_COOKIE_NAME, value, {
            expires: new Date(new Date().setDate(new Date().getDate() + CULTURE_COOKIE_EXPIRES_DAYS))
        });
        for (const index of Object.keys(this._subscribers)) {
            if (this._subscribers[index] instanceof Function) {
                this._subscribers[index]();
            }
        }
    }

    static get cultures() {
        return window.viewData.options.map((option) => ({
            label: option.uiText,
            value: option.value
        }));
    }

    static init() {
        const cookieValue = new Cookies().get(CULTURE_COOKIE_NAME);
        this.culture = cookieValue ? cookieValue : window.viewData.default;
    }

    static getDefaultCulture() {
        const browserCulture = window.navigator.language.split("-").pop().toLowerCase();
        const containsBrowserCulture = this.cultures.some(culture => culture.value === browserCulture);
        return containsBrowserCulture
            ? browserCulture
            : window.viewData.default;
    }

    static getLabel(type, path) {
        path = [
            type,
            this.culture
        ].concat(path.split("."));

        let buffer = window.viewData;
        for (const key of path) {
            buffer = buffer[key];
            if (!buffer) {
                return null;
            }
        }

        return buffer;
    }

    static subscribeCultureChange(callback) {
        const index = this._subscribersIndex++;
        this._subscribers[index] = callback;
        return index;
    }

    static unsubscribeCultureChange(index) {
        delete this._subscribers[index];
    }
}

window.cultureService = CultureService;