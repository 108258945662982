import React, { Component } from "react";
import { AuthService } from "../../services/auth.service";
import { Link } from "react-router-dom";
import moment from "moment";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

const fxType = "fxType";

const types = {
  email: "email",
  username: "username",
  firstName: "firstName",
  surname: "surname",
  password: "password",
  passwordConfirm: "passwordConfirm",
  phone: "phone",
  companyID: "companyID",
  birthdayAt: "birthdayAt",
  description: "description",
  requiredType: "required",
  acceptedTermsOfService: "acceptedTermsOfService"
};
const phoneRegex = /^[0-9 +]+$/;
const styles = {
  submit: {
    position: "relative",
    top: "5px",
    left: "50%",
    transform: "translateX(-50%)",
    height: "50px"
  },
  formGroup: {
    margin: "0 0.5% 15px 0.5%"
  }
};

const initialState = {
  fields: {
    username: {
      value: "",
      errors: {
        required: false
      },
      invalid: false
    },
    firstName: {
      value: "",
      errors: {
        required: false
      },
      invalid: false
    },
    surname: {
      value: "",
      errors: {
        required: false
      },
      invalid: false
    },
    phone: {
      value: "",
      errors: {
        phone: false
      },
      extraField: true,
      invalid: false
    },
    companyID: {
      value: "",
      errors: {
        companyID: false
      },
      extraField: true,
      invalid: false
    },
    birthdayAt: {
      value: "",
      disabled: true,
      errors: {
        birthdayAt: false
      },
      extraField: true,
      invalid: false
    },
    description: {
      value: "",
      extraField: true,
      invalid: false
    },
    email: {
      value: "",
      errors: {
        required: false,
        email: false
      },
      invalid: false
    },
    password: {
      value: "",
      errors: {
        required: false,
        password: false
      },
      invalid: false
    },
    passwordConfirm: {
      value: "",
      errors: {
        required: false,
        passwordConfirm: false
      },
      invalid: false
    },
    acceptedTermsOfService: {
      value: false,
      errors: {
        required: true
      },
      invalid: false
    }
  },
  disabledLogin: false,
  error: false,
  errorMessages: []
};

export default class RegisterPage extends Component {
  form = React.createRef();
  state = {
    ...initialState
  };
  wrapper = React.createRef();

  componentDidMount() {
    this.setEnterKeyListen(true);
  }
  componentWillUnmount() {
    this.setEnterKeyListen();
  }
  setEnterKeyListen(status) {
    const form = this.form.current;
    if (form) {
      if (status) {
        form.addEventListener("keyup", this.handleKey);
        return;
      }
      form.removeEventListener("keyup", this.handleKey);
    }
  }
  handleKey = e => {
    if (e.key === "Enter") {
      this.handleRegister();
    }
  };
  validateEmail(e) {
    const emailVal = e ? e.target.value : this.state.fields.email.value;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(emailVal).toLowerCase());
    if (!valid) {
      this.validateError(types.email, types.email);
      return false;
    }
    return true;
  }
  validatePassword(e, passwordType) {
    const passwordVal = e ? e.target.value : this.state.fields[passwordType].value;
    if (passwordType === types.passwordConfirm) {
      if (passwordVal !== this.state.fields.password.value) {
        this.validateError(types.passwordConfirm, types.passwordConfirm);
        return false;
      }
      return true;
    }
    const re = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])).{8,}$/;
    const valid = re.test(String(passwordVal));
    if (!valid) {
      this.validateError(passwordType, passwordType);
      return false;
    }
    return true;
  }
  validatePhone(e) {
    const phoneNum = e.target.value;
    if (!phoneNum) {
      return;
    }
    const valid = phoneRegex.test(String(phoneNum).toLowerCase());
    if (!valid) {
      this.validateError(types.phone, types.phone);
    }
  }
  validateDate(e) {
    const dateStr = e.target.value;
    if (!dateStr) {
      return;
    }
    const date = moment(dateStr, "DD/MM/YYYY", true);
    if (!date.isValid() || date > moment()) {
      this.validateError(types.birthdayAt, types.birthdayAt);
    }
  }
  validateAcceptedTermsOfService(e) {
    if (e) {
      const fields = { ...this.state.fields };
      fields.acceptedTermsOfService.value = e.target.checked;
      fields.acceptedTermsOfService.invalid = !e.target.checked;
      this.setState({ fields });
    }
    const value = this.state.fields["acceptedTermsOfService"].value;
    if (!value) {
      this.validateError(types.acceptedTermsOfService, "required");
      return false;
    }
    return true;
  }
  validateError(fieldType, errorType) {
    this.setState(state => ({
      ...state,
      error: true,
      fields: {
        ...state.fields,
        [fieldType]: {
          ...state.fields[fieldType],
          errors: {
            ...state.fields[fieldType].errors,
            [errorType]: true
          },
          invalid: true
        }
      }
    }));
  }
  validate(key) {
    if (key) {
      return this.checkRequiredField(key);
    }
    let valid = true;
    Object.keys(this.state.fields).forEach(fieldType => {
      if (!valid) {
        return false;
      }
      if (!this.state.fields[fieldType].disabled) {
        let validField = this.checkRequiredField(fieldType);
        if (fieldType === types.password || fieldType === types.passwordConfirm) {
          validField = this.validatePassword(null, fieldType);
        }
        if (fieldType === types.email) {
          validField = this.validateEmail(null);
        }
        if (fieldType === types.acceptedTermsOfService) {
          validField = this.validateAcceptedTermsOfService(null);
        }
        console.log(validField, fieldType)
        if (valid) {
          valid = validField;
        } else {
          return false;
        }
      }
    });
    return valid;
  }
  checkRequiredField(fieldType) {
    const field = this.state.fields[fieldType];
    if (!field.value && !field.extraField) {
      this.validateError(fieldType, types.requiredType);
      return false;
    }
    return true;
  }
  handleChange(e, type) {
    const inputVal = e.target.value;
    if (type === types.password) {
      if (
        this.state.fields.passwordConfirm.value &&
        inputVal === this.state.fields.passwordConfirm.value
      ) {
        this.setState(state => ({
          fields: {
            ...state.fields,
            passwordConfirm: {
              ...state.fields.passwordConfirm,
              errors: {
                ...state.fields.passwordConfirm.errors,
                passwordConfirm: false
              },
              invalid: false
            }
          }
        }));
      }
    }
    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...initialState[type],
          value: inputVal
        }
      },
      error: false,
      errorMessages: []
    }));
  }
  handleRegister = () => {
    if (!this.validate()) {
      console.log("validate false");
      return;
    }
    this.setState({
      disabledLogin: true
    });
    const data = Object.keys(this.state.fields).reduce((res, currentKey) => {
      const currentField = this.state.fields[currentKey];
      const currentValue =
        currentKey === types.birthdayAt
          ? moment(currentField.value).format() !== "Invalid date"
            ? moment(currentField.value).format()
            : null
          : currentField.value;
      return {
        ...res,
        [currentKey]: currentValue ? currentValue : null
      };
    }, {});
    AuthService.register(data, this.onAuthResult);
  };
  onAuthResult = data => {
    this.setState({
      disabledLogin: false
    });
    console.log("__DATA", data);
    if (data.isError) {
      const wrapper = this.wrapper.current;
      if (wrapper) {
        wrapper.scrollTo(0, 0);
      }
      switch (data.statusCode) {
        case 400:
          this.setState({
            errorMessages: [
              data.errorMessage ||
              `'${CultureService.getLabel("texts", "registerPage.userNameErrorFirst")}' '${this.state.fields.email.value}' '${CultureService.getLabel("texts", "registerPage.userNameErrorlast")}'`
            ]
          });
          break;
        default:
          this.setState({ errorMessages: [data.errorMessage || CultureService.getLabel("texts", "registerPage.defaultError")] });
          break;
      }
      return;
    } else {
      this.props.history.push({
        pathname: "/register-confirmation",
        query: { alowed: true }
      });
    }
  };
  getAppTitle() {
    const theme = window.configData && window.configData.theme;
    switch (theme) {
      case "hhi":
        return "HERMETIC SEEKERS";
      default:
        return fxType;
    }
  }
  render() {
    const { fields } = this.state;
    const hasError = this.state.errorMessages.map((errorMsg, index) => (
      <span key={errorMsg + index}>{errorMsg}</span>
    ));
    return (
      <div className="container container-middle container-small" style={{ alignSelf: 'flex-start' }}>
        <div ref={this.wrapper}>
          {this.state.disabledLogin && <div className="loaderSpinner" />}
          <div
            className="contentWrapper"
            style={{
              visibility: this.state.disabledLogin ? "hidden" : "visible"
            }}
          >
            <Header />
            <div className="form" ref={this.form} action="">
              <h3 className="title"><Label name="registerPage.title" /></h3>
              {!!hasError.length && (
                <p className="error text-center">{hasError}</p>
              )}
              <div className="registerFieldsWrapper">
                <div
                  style={styles.formGroup}
                  className={
                    fields.username.invalid ? "form-group error" : "form-group"
                  }
                >
                  <label htmlFor="username"><Label name="registerPage.userName" /></label>
                  <input
                    type="text"
                    id="username"
                    value={fields.username.value}
                    onChange={e => this.handleChange(e, types.username)}
                    onBlur={() => this.validate(types.username)}
                  />
                  {fields.username.invalid && (
                    <div>
                      {fields.username.errors.required && (
                        <span> <Label name="registerPage.userNameRequired" /> </span>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={styles.formGroup}
                  className={
                    fields.firstName.invalid ? "form-group error" : "form-group"
                  }
                >
                  <label htmlFor="firstName"><Label name="registerPage.firstName" /></label>
                  <input
                    type="text"
                    id="firstName"
                    value={fields.firstName.value}
                    onChange={e => this.handleChange(e, types.firstName)}
                    onBlur={() => this.validate(types.firstName)}
                  />
                  {fields.firstName.invalid && (
                    <div>
                      {fields.firstName.errors.required && (
                        <span> <Label name="registerPage.firstNameError" /> </span>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={styles.formGroup}
                  className={
                    fields.surname.invalid ? "form-group error" : "form-group"
                  }
                >
                  <label htmlFor="surname"><Label name="registerPage.surname" /></label>
                  <input
                    type="text"
                    id="surname"
                    value={fields.surname.value}
                    onChange={e => this.handleChange(e, types.surname)}
                    onBlur={() => this.validate(types.surname)}
                  />
                  {fields.surname.invalid && (
                    <div>
                      {fields.surname.errors.required && (
                        <span> <Label name="registerPage.surnameRequired" /> </span>
                      )}
                    </div>
                  )}
                </div>

                <div
                  style={styles.formGroup}
                  className={
                    fields.email.invalid ? "form-group error" : "form-group"
                  }
                >
                  <label htmlFor="email"><Label name="registerPage.emailAddress" /></label>
                  <input
                    type="text"
                    id="email"
                    value={fields.email.value}
                    onChange={e => this.handleChange(e, types.email)}
                    onBlur={e => this.validateEmail(e)}
                  />
                  {fields.email.invalid && (
                    <div>
                      {fields.email.errors.required && (
                        <span> <Label name="registerPage.emailRequired" /> </span>
                      )}
                      {fields.email.errors.email && (
                        <span> <Label name="registerPage.emailFormatError" /> </span>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={styles.formGroup}
                  className={
                    fields.password.invalid ? "form-group error" : "form-group"
                  }
                >
                  <label htmlFor="password"><Label name="registerPage.password" /></label>
                  <input
                    type="password"
                    id="password"
                    value={fields.password.value}
                    onChange={e => this.handleChange(e, types.password)}
                    onBlur={e => this.validatePassword(e, types.password)}
                  />
                  {fields.password.invalid && (
                    <div>
                      {fields.password.errors.required && (
                        <span> <Label name="registerPage.passwordError" /> </span>
                      )}
                      {fields.password.errors.password && (
                        <span>
                          <Label name="registerPage.passwordFormatError" />
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={styles.formGroup}
                  className={
                    fields.passwordConfirm.invalid
                      ? "form-group error"
                      : "form-group"
                  }
                >
                  <label htmlFor="passwordConfirm"><Label name="registerPage.confirmPassword" /></label>
                  <input
                    type="password"
                    id="passwordConfirm"
                    value={fields.passwordConfirm.value}
                    onChange={e => this.handleChange(e, types.passwordConfirm)}
                    onBlur={e =>
                      this.validatePassword(e, types.passwordConfirm)
                    }
                  />
                  {fields.passwordConfirm.invalid && (
                    <div>
                      {fields.passwordConfirm.errors.required && (
                        <span> <Label name="registerPage.confirmPasswordRequired" /> </span>
                      )}
                      {fields.passwordConfirm.errors.passwordConfirm && (
                        <span> <Label name="registerPage.confirmPasswordSameText" /> </span>
                      )}
                    </div>
                  )}
                </div>
                {/*
                            <div style={styles.formGroup} className={fields.phone.invalid ? "form-group error" : "form-group"}>
                                <label htmlFor="phone">Phone</label>
                                <input type="text" id="phone"
                                    value={fields.phone.value}
                                    onBlur={e => this.validatePhone(e)}
                                    onChange={e => this.handleChange(e, types.phone)}
                                />
                                {fields.phone.invalid && <div>
                                    {fields.phone.errors.phone && <span> Invalid phone number </span>}
                                </div>}
                            </div>
                            <div style={styles.formGroup} className={fields.companyID.invalid ? "form-group error" : "form-group"}>
                                <label htmlFor="companyID">Company ID</label>
                                <input type="text" id="companyID"
                                    value={fields.companyID.value}
                                    onChange={e => this.handleChange(e, types.companyID)}
                                />
                            </div>
                            <div style={styles.formGroup} className={fields.birthdayAt.invalid ? "form-group error" : "form-group"}>
                                <label htmlFor="birthdayAt">Birth Date</label>
                                <input type="text" id="birthdayAt"
                                    value={fields.birthdayAt.value}
                                    onChange={e => this.handleChange(e, types.birthdayAt)}
                                    onBlur={e => this.validateDate(e)}
                                    placeholder="dd/mm/yyyy"
                                />
                                {fields.birthdayAt.invalid && <div>
                                    {fields.birthdayAt.errors.birthdayAt && <span> Invalid date </span>}
                                </div>}
                            </div>*/}
              </div>
              <div className="mb-1"></div>
              <div
                style={styles.formGroup}
                className={
                  fields.acceptedTermsOfService.invalid
                    ? "form-group error"
                    : "form-group"
                }
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  id="acceptedTermsOfService"
                  name="acceptedTermsOfService"
                  value={fields.acceptedTermsOfService.value}
                  onChange={e => this.validateAcceptedTermsOfService(e)}
                />
                <label for="acceptedTermsOfService">
                  <Label name="registerPage.acceptedTermsOfService" />
                </label>
                {fields.acceptedTermsOfService.invalid && (
                  <div>
                    {fields.acceptedTermsOfService.errors.required && (
                      <span> <Label name="registerPage.acceptedTermsOfServiceRequired" /> </span>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-2"></div>
              <button
                className="btn"
                style={styles.submit}
                onClick={!this.state.disabledLogin && this.handleRegister}
              >
                <Label name="registerPage.createAccount" />
              </button>
              <p className="text-center">
                <Link to="/login" className="link link--primary">
                  <Label name="registerPage.signInToAccount" />
                </Link>
              </p>
              <hr />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}