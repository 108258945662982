import * as React from 'react';

import "../styles/components/default/_selectBox.scss";

// export interface ISelectBoxItem {
//     content: React.ReactElement;
//     [key: string]: any;
//     onClick?: () => void;
// }

// interface ISelectBoxProps {
//     id: string;
//     className?: string;
//     content?: React.ReactElement;
//     items: ISelectBoxItem[];
//     active?: ISelectBoxItem | undefined;
//     align?: 'left' | 'right' | undefined;
//     onChange?: (item: ISelectBoxItem | undefined) => void;
// }

export default class SelectBox extends React.Component {
    currentRef = React.createRef();
    focusOutClass = null;

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            className: this.props.className,
            content: this.props.content,
            items: this.props.items || [],
            active: this.props.active
                ? this.props.active
                : this.props.items && !this.props.content
                    ? this.props.items[0]
                    : undefined,
            align: this.props.align || 'left'
        };
    }

    componentDidMount() {
        this.currentRef
            .current
            .addEventListener('focusout', () => setTimeout(() => {
                if (!this.currentRef.current) {
                    return;
                }
                if (this.focusOutClass) {
                    this.currentRef.current.parentElement.classList.remove(this.focusOutClass);
                }
                delete this.currentRef.current.dataset.state;
            }));

    }

    onChange(index) {
        const active = this.state.items[index];
        if (this.props.onChange) {
            this.props.onChange(active);
        }
        if (!this.state.content) {
            this.setState({ active });
        }
    }

    onClick(index) {
        const active = this.state.items[index];
        if (active.onClick) {
            active.onClick();
        }
    }

    open(classNameForOpen) {
        if (!this.currentRef.current || this.currentRef.current.dataset.state === 'focused') {
            return;
        }
        this.currentRef.current.focus();
        if (classNameForOpen) {
            this.focusOutClass = classNameForOpen;
            this.currentRef.current.parentElement.classList.add(classNameForOpen);
        }
        this.currentRef.current.dataset.state = 'focused';
    }

    render() {
        const className = [
            'select-box',
            this.state.align === 'right'
                ? 'select-box--right'
                : 'select-box--left'
        ].concat((this.state.className || '').split(' '))
            .join(' ');

        return (
            <div
                className={className}
            >
                <div
                    className="select-box__current"
                    tabIndex={1}
                    ref={this.currentRef}
                >
                    {this.state.content
                        ? this.state.content
                        : this.state.items.map((item, index) =>
                            <div
                                className="select-box__value"
                                key={index}
                            >
                                <input
                                    className="select-box__input"
                                    type="radio"
                                    id={`${this.state.id}${index}`}
                                    value={index}
                                    name={this.state.id}
                                    checked={item === this.state.active}
                                    onChange={() => this.onChange(index)}
                                />
                                <p className="select-box__input-text">{item.content}</p>
                            </div>
                        )}
                    <div className="select-box__icon" />
                </div>
                <ul className="select-box__list">
                    {this.state.items.map((item, index) =>
                        <li key={index}>
                            <label
                                className={[
                                    'select-box__option',
                                    this.state.active === item ? 'select-box__option--active' : undefined
                                ].join(' ')}
                                htmlFor={`${this.state.id}${index}`}
                                aria-hidden="true"
                                onClick={() => this.onClick(index)}
                            >
                                {item.content}
                            </label>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}