import React from "react";
import CultureService from "../services/culture.service";
import Label from "./label";

export default class Footer extends React.Component {

  componentDidMount() {
    CultureService.subscribeCultureChange(() => this.setState({ ...this.state }))
  }

  render() {
    return (
      <footer>
        <p>
          <Label type="settings" name="footerText" />
        </p>
        <p>
          {CultureService.getLabel("settings", "privacyPolicyUrl") && (
            <a target="_blank" rel="noopener noreferrer" href={CultureService.getLabel("settings", "privacyPolicyUrl")}>
              <Label name="footer.privacyPolicy" />
            </a>
          )}
          {CultureService.getLabel("settings", "privacyPolicyUrl") && CultureService.getLabel("settings", "termsOfUseUrl") && (
            <span> | </span>
          )}
          {CultureService.getLabel("settings", "termsOfUseUrl") && (
            <a target="_blank" rel="noopener noreferrer" href={CultureService.getLabel("settings", "termsOfUseUrl")}>
              <Label name="footer.termsOfUse" />
            </a>
          )}
        </p>
      </footer>
    );
  }

}