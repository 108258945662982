import React from "react";
import ReactDOM from "react-dom";
import Login from "./accounts/loginPage";
import Register from "./accounts/registerPage";
import RegisterConfirmation from "./accounts/registerConfirmationPage";
import Activate from "./accounts/activatePage";
import ErrorPage from "./accounts/errorPage";
import RecoveryPage from "./accounts/recoveryPage";
import RecoveryConfirmationPage from "./accounts/recoveryConfirmationPage";
import ResetConfirmationPage from "./accounts/resetConfirmationPage";
import ResetPage from "./accounts/resetPage";
import { Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import "./styles/index.scss";
// import LeftBrandLogo from "./components/leftBrandLogo";
// import CultureSwitch from "./components/cultureSwitch";
import CultureService from "./services/culture.service";

const routing = (
  <Router basename="/accounts">
    {/* <LeftBrandLogo /> */}
    {/* <CultureSwitch /> */}

    <Route exact path="/" render={() => <Redirect to="/login" />} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/register" component={Register} />
    <Route
      exact
      path="/register-confirmation"
      component={RegisterConfirmation}
    />
    <Route exact path="/activate" component={Activate} />
    <Route exact path="/error" component={ErrorPage} />
    <Route exact path="/recovery" component={RecoveryPage} />
    <Route
      exact
      path="/recovery-confirmation"
      component={RecoveryConfirmationPage}
    />
    <Route exact path="/reset" component={ResetPage} />
    <Route
      exact
      path="/reset-confirmation"
      component={ResetConfirmationPage}
    />
  </Router>
);

function load(callback) {
  if (!window.configData.viewDataUrl) {
    return callback();
  }
  fetch(window.configData.viewDataUrl)
    .then(function (response) {
      try {
        if (response.ok) {
          response.json().then(function (viewData) {
            let viewDataStr = JSON.stringify(viewData);
            viewDataStr = viewDataStr.replace(/\${API_URL}/g, window.configData.apiURL);
            window.viewData = JSON.parse(viewDataStr);
            callback();
          }).catch((error) => callback(error));
        } else {
          callback(response.status);
        }
      } catch (error) {
        callback(error);
      }
    })
    .catch((error) => callback(error));
}

function run(error) {
  if (error) {
    console.warn(error);
  }

  CultureService.init();

  // set theme and env dependent data
  const theme = window.configData && window.configData.theme ? window.configData.theme : "default";
  document.getElementsByTagName('html')[0].classList.add(theme);

  const viewDataBackground = (window.viewData && CultureService.getLabel("settings", "backgroundLinks")) ? CultureService.getLabel("settings", "backgroundLinks.desktop") : '';
  const appTitle = window.viewData ? CultureService.getLabel("settings", "appTitle") : '';

  document.title = appTitle;
  // document.getElementsByTagName('body')[0].style.backgroundImage = `url('${viewDataBackground}')`;

  // Add background image when desktop device
  const style = document.createElement('style');
  style.innerHTML = `
  @media (min-width: 768px) {
    body {
      background-image: url(${viewDataBackground});
    }
  }
`;
  document.head.appendChild(style);

  ReactDOM.render(routing, document.getElementById('root'));
}

load(run);