import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Label from "../../components/label";

export default class ResetConfirmationPage extends Component {
  componentDidMount() {
    if (!this.props.location.query || !this.props.location.query.alowed) {
      this.props.history.push("/login");
    }
  }
  render() {
    return (
      <div className="container container-middle container-small">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title"><Label name="resetConfirmationPage.confirmation" /></h3>
            <h4 className="title"><Label name="resetConfirmationPage.subTitle" /></h4>
            <p className="text-center"><Label name="resetConfirmationPage.infoText" /></p>
            <p className="text-center">
            <Label name="resetConfirmationPage.instructionText" />
            </p>
            <div className="mb-big"></div>
            <Link to="/login" className="btn">
            <Label name="resetConfirmationPage.linkToSigningPage" />
            </Link>
            <hr className="distance" />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
