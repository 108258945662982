import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

const passwordType = "password";
const passwordOldType = "passwordOld";
const passwordConfirmType = "passwordConfirm";
const requiredType = "required";

const initialFormState = {
  password: {
    value: "",
    errors: {
      required: false,
      password: false
    },
    invalid: false
  },
  passwordConfirm: {
    value: "",
    errors: {
      required: false,
      passwordConfirm: false
    },
    invalid: false
  },
  passwordOld: {
    value: "",
    errors: {
      required: false,
      passwordOld: false
    },
    invalid: false
  },
  disabledSubmit: false,
  error: false,
  errorMessages: []
};

export default class resetPage extends Component {
  state = {
    form: { ...initialFormState },
    token: null,
    username: ""
  };
  getParam(param) {
    const urlSearch = new URLSearchParams(window.location.search);
    return urlSearch.get(param) || null;
  }
  componentDidMount() {
    const username = this.getParam('username');
    if (!username) {
      this.goToErrorPage(CultureService.getLabel("texts", "resetPage.resetLinkInvalid"));
      return;
    }
    const token = this.getParam('token');
    this.setState({ token, username });
  }
  goToErrorPage(message) {
    this.props.history.push({
      pathname: "/error",
      query: { error: message }
    });
  }
  validatePassword(e, passwordType) {
    const passwordVal = e.target.value;
    switch (passwordType) {
      case passwordConfirmType:
        if (passwordVal !== this.state.form.password.value) {
          this.validateError(passwordConfirmType, passwordConfirmType);
        }
        break;
      case passwordOldType:
        if (
          passwordType === passwordOldType &&
          this.state.form.passwordOld.value
        ) {
          this.validateError(passwordType, requiredType);
        }
        break;
      default:
        const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        const valid = re.test(String(passwordVal));
        if (!valid) {
          this.validateError(passwordType, passwordType);
        }
        break;
    }
  }
  validateError(fieldType, errorType) {
    this.setState(state => ({
      form: {
        ...state.form,
        error: true,
        [fieldType]: {
          ...state.form[fieldType],
          errors: {
            ...state.form[fieldType].errors,
            [errorType]: true
          },
          invalid: true
        }
      }
    }));
  }
  handleSubmit = () => {
    if (!this.state.form.error) {
      this.setState({
        disabledSubmit: true
      });
      AuthService.reset(
        this.state.username,
        this.state.form.passwordOld.value,
        this.state.token,
        this.state.form.password.value,
        this.state.form.passwordConfirm.value,
        this.onReset
      );
    }
  };
  onReset = data => {
    if (data.errorStatus) {
      let errorMessages = [];
      switch (data.errorStatus) {
        case 400:
          errorMessages = [CultureService.getLabel("texts", "resetPage.resetPasswordTokenInvalid")];
          break;
        default:
          errorMessages = [CultureService.getLabel("texts", "resetPage.defaultError")];
          break;
      }
      this.setState(state => ({
        disabledSubmit: false,
        form: { ...state.form, errorMessages }
      }));
      return;
    }
    this.props.history.push({
      pathname: "/reset-confirmation",
      query: { alowed: true }
    });
  };
  handleChange(e, type) {
    const inputVal = e.target.value;
    this.setState(state => ({
      form: {
        ...state.form,
        [type]: {
          ...initialFormState[type],
          value: inputVal
        },
        error: false,
        errorMessages: []
      }
    }));
  }
  render() {
    const hasError = this.state.form.errorMessages.map((errorMsg, index) => (
      <span key={errorMsg + index}>{errorMsg}</span>
    ));
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    const returnUrl = searchParams.get("returnUrl");
    const form = { ...this.state.form };
    return (
      <div className="container container-small container-middle">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title"><Label name="resetPage.title" /></h3>
            {!!hasError.length && (
              <p className="error text-center">{hasError}</p>
            )}
            {!this.state.token && (
              <div
                className={
                  form.passwordOld.invalid ? "form-group error" : "form-group"
                }
              >
                <label htmlFor="passwordOld"><Label name="resetPage.oldPassword" /></label>
                <input
                  type="password"
                  id="passwordOld"
                  value={form.passwordOld.value}
                  onChange={e => this.handleChange(e, passwordOldType)}
                />
                {form.passwordOld.invalid && (
                  <div>
                    {form.passwordOld.errors.required && (
                      <span><Label name="resetPage.oldPasswordRequired" /></span>
                    )}
                  </div>
                )}
              </div>
            )}
            <div
              className={
                form.password.invalid ? "form-group error" : "form-group"
              }
            >
              <label htmlFor="password"><Label name="resetPage.password" /></label>
              <input
                type="password"
                id="password"
                value={form.password.value}
                onChange={e => this.handleChange(e, passwordType)}
                onBlur={e => this.validatePassword(e, passwordType)}
              />
              {form.password.invalid && (
                <div>
                  {form.password.errors.required && (
                    <span><Label name="resetPage.passwordError" /></span>
                  )}
                  {form.password.errors.password && (
                    <span>
                      <Label name="resetPage.passwordFormatError" />
                    </span>
                  )}
                </div>
              )}
            </div>
            <div
              className={
                form.passwordConfirm.invalid ? "form-group error" : "form-group"
              }
            >
              <label htmlFor="passwordConfirm"><Label name="resetPage.confirmPassword" /></label>
              <input
                type="password"
                id="passwordConfirm"
                value={form.passwordConfirm.value}
                onChange={e => this.handleChange(e, passwordConfirmType)}
                onBlur={e => this.validatePassword(e, passwordConfirmType)}
              />
              {form.passwordConfirm.invalid && (
                <div>
                  {form.passwordConfirm.errors.required && (
                    <span><Label name="resetPage.confirmPasswordRequired" /></span>
                  )}
                  {form.passwordConfirm.errors.passwordConfirm && (
                    <span><Label name="resetPage.confirmPasswordSameText" /></span>
                  )}
                </div>
              )}
            </div>
            <div className="mb-3"></div>
            <button
              className="btn mb-1"
              onClick={!this.state.disabledSubmit && this.handleSubmit}
            >
              <Label name="resetPage.submit" />
            </button>
            {returnUrl ? (
              <a href={returnUrl + (window.location.hash || '')} className="btn btn-secondary">
                <Label name="resetPage.cancel" />
              </a>
            ) : (
              <Link to="/login" className="btn btn-secondary">
                <Label name="resetPage.cancel" />
              </Link>
            )}
            <hr className="distance" />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
