import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Label from "../../components/label";

export default class ErrorPage extends Component {
  componentDidMount() {
    if (!this.props.location.query || !this.props.location.query.error) {
      this.props.history.push("/login");
    }
  }
  render() {
    const errorMessage = this.props.location.query
      ? this.props.location.query.error
      : null;
    return (
      <div className="container container-middle container-small">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title"><Label name="errorPage.title" /></h3>
            <h4 className="title error mb-big">{errorMessage}</h4>
            <Link to="/login" className="btn">
              <Label name="errorPage.linkToSigningPage" />
            </Link>
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
