import axios from "axios";
import Cookies from "universal-cookie";
import CultureService from "./culture.service";
export const TokenErrorCode = {
  UserNotAuthenticated: "USER_NOT_AUTHENTICATED",
  UserNotActivated: "USER_NOT_ACTIVATED",
  UnknownError: "UNKNOWN_ERROR",
};

export const UnknownErrorMessage = {
  RecordNotFound: "users.GetAccountByName: record not found",
};

export const AUTH_COOKIE_NAME = "fluxorm_access_token";
const REFRESH_COOKIE_NAME = "fluxorm_refresh_token";
const DEMO_USED_COOKIE_NAME = "fluxorm_demo_used";

export class AuthService {
  static demoUserNames = [];
  static user = {};
  static cookieService = new Cookies();

  get isUserAuthorized() {
    return this.cookieService.check(AUTH_COOKIE_NAME);
  }
  get accessToken() {
    return this.cookieService.get(AUTH_COOKIE_NAME);
  }
  static async login(username, password, rememberMe, callback) {
    const headers = {
      "Content-Type": "application/json",
    };
    const urlParams = new URLSearchParams(window.location.search);
    let appID = this.getAppID();
    if (urlParams.has("appID")) {
      appID = Number(urlParams.get("appID"));
      console.log("appID from url: %d", appID);
    }
    const apiURL = this.getApiURL();
    await axios
      .post(
        `${apiURL}/v1/accounts/token`,
        {
          username,
          password,
          applicationID: appID,
          rememberMe,
        },
        {
          headers,
        }
      )
      .then((res) => {
        const tokenResult = res.data;
        const token = tokenResult.access_token;
        const tokenJson = this.convertTokenToJson(token);
        // token is stored in cookie by server
        //this.saveTokenCookies(tokenJson, tokenResult);
        if (this.isDemoUsername(username)) {
          this.saveDemoAccountCookies();
        }

        this.user = this.convertTokenJsonToUser(tokenJson);
        this.redirectToApp(this.user, token);
      })
      .catch((error) => {
        console.log(error, error.response);
        const errCode = error.response.data.errorCode;
        const message = error.response.data.message;
        const dataJirka = error.response.data;
        console.log(message, callback);
        console.log(dataJirka, callback);
        console.log(errCode, callback);
        switch (errCode) {
          case TokenErrorCode.UserNotAuthenticated:
            callback({
              error: CultureService.getLabel(
                "texts",
                "authService.userNotAuthenticated"
              ),
            });
            break;
          case TokenErrorCode.UserNotActivated:
            callback({
              error: CultureService.getLabel("texts", "authService.tokenError"),
            });
            break;
          case TokenErrorCode.UnknownError:
            console.log(UnknownErrorMessage.RecordNotFound, callback);
            switch (message) {
              case UnknownErrorMessage.RecordNotFound:
                callback({
                  error: CultureService.getLabel(
                    "texts",
                    "authService.userDoesNotExist"
                  ),
                });
                break;
              default:
                console.log("___DEFAULT", callback);
                callback({
                  error: CultureService.getLabel(
                    "texts",
                    "authService.defaultError"
                  ),
                });
                break;
            }
            break;
          default:
            callback({
              error: CultureService.getLabel(
                "texts",
                "authService.defaultError"
              ),
            });
            break;
        }
      });
  }
  static redirectToApp(user, token) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("return_url")) {
      const goToUrl =
        urlParams.get("return_url") + (window.location.hash || "");
      window.location.href = goToUrl;
    } else {
      console.log("return_url not provided, getting default app url from fx");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const apiURL = this.getApiURL();
      axios
        .post(
          `${apiURL}/v1/uploads/json/sync`,
          {
            eventID: 61,
            eventGroupID: 6,
            collectionID: "fxlogin",
            data: {},
          },
          {
            headers,
          }
        )
        .then((res) => {
          //console.log(res);
          const redirectUrl = res.data.defaultAppUrl;
          console.log("redirecting to url: %s", redirectUrl);
          window.location.href = redirectUrl;
        })
        .catch((e) => {
          console.log("get url to redirect error: ", e);
        });
    }
  }

  static loginAsDemo(callback) {
    const demo = "demo";
    return this.login(demo, demo, false, callback);
  }

  static register(data, callback) {
    const headers = {
      "Content-Type": "application/json",
    };
    const apiURL = this.getApiURL();
    axios
      .post(
        `${apiURL}/v1/accounts/register?lang=${CultureService.culture}`,
        {
          ...data,
        },
        {
          headers,
        }
      )
      .then((res) => {
        console.log(res);
        const statusCode = res.status;
        const isError = statusCode >= 400 && statusCode <= 500;
        const errorMessage = isError
          ? CultureService.getLabel("texts", "resetPage.userFailedRegister")
          : null;
        callback({
          statusCode,
          isError,
          errorMessage,
        });
      })
      .catch((e) => {
        const statusCode = e.response.status;
        const isError = statusCode >= 400 && statusCode <= 500;
        const errorMessage =
          e.response.data.message.charAt(0).toUpperCase() +
          e.response.data.message.slice(1);
        callback({
          statusCode,
          isError,
          errorMessage,
        });
      });
  }

  static recovery(email, callback) {
    const apiURL = this.getApiURL();
    axios
      .put(
        `${apiURL}/v1/accounts/reset?username=${email}&lang=${CultureService.culture}`,
        {}
      )
      .then((res) => {
        console.log(res);
        callback({
          data: res.data,
        });
      })
      .catch((e) => {
        console.log(e.response);
        callback({
          errorStatus: e.response,
        });
      });
  }

  static activate(username, activationToken, onError) {
    const apiURL = this.getApiURL();
    axios
      .put(
        `${apiURL}/v1/accounts/activate?username=${username}&activationToken=${activationToken}`,
        {}
      )
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
        onError(CultureService.getLabel("texts", "resetPage.activationFailed"));
      });
  }

  static reset(
    username,
    oldPassword,
    resetToken,
    newPassword,
    newPasswordConfirm,
    callback
  ) {
    const apiURL = this.getApiURL();
    axios
      .put(`${apiURL}/v1/accounts/password`, {
        username,
        oldPassword,
        resetToken,
        newPassword,
        newPasswordConfirm,
      })
      .then((res) => {
        console.log(res);
        callback(res.data);
      })
      .catch((e) => {
        console.log(e);
        callback({
          errorStatus: e.response.status,
        });
      });
  }

  static isDemoUsername(username) {
    return !!this.demoUserNames.filter(
      (a) => a.toLowerCase() === username.toLowerCase()
    ).length;
  }

  static isDemoAccountUsed() {
    return this.cookieService.check(DEMO_USED_COOKIE_NAME);
  }

  static isDemoUsernameBlocked(username) {
    return this.isDemoUsername(username) && this.isDemoAccountUsed();
  }

  static loadUser() {
    const accessToken = this.cookieService.get(AUTH_COOKIE_NAME);
    if (!accessToken) {
      return;
    }
    const tokenJson = this.convertTokenToJson(accessToken);
    this.user = this.convertTokenJsonToUser(tokenJson);
    setTimeout(() => {
      this.onLogin.emit();
    }, 1);
  }

  static loadDemoAccounts() {
    const apiURL = this.getApiURL();
    axios
      .get(`${apiURL}/v1/apps/1/demoUsernames`)
      .then((demoAccountsResult) => {
        this.demoUserNames = demoAccountsResult.data.usernames || [];
        console.log(this.demoUserNames);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static saveTokenCookies(json, tokenResult) {
    const expiration = new Date(Number(json["exp"]));

    let expirationDays = expiration.getUTCDate();
    expirationDays += expiration.getUTCHours() / 24;
    expirationDays += expiration.getUTCMinutes() / 60 / 24;
    expirationDays += expiration.getUTCSeconds() / 60 / 60 / 24;

    this.cookieService.set(
      AUTH_COOKIE_NAME,
      tokenResult.access_token,
      expirationDays,
      "/"
    );
    this.cookieService.set(
      REFRESH_COOKIE_NAME,
      tokenResult.refresh_token,
      expirationDays,
      "/"
    );
  }

  static saveDemoAccountCookies() {
    this.cookieService.set(
      DEMO_USED_COOKIE_NAME,
      "true",
      new Date(2099, 1, 1),
      "/"
    );
  }

  static convertTokenToJson(jwt) {
    return JSON.parse(window.atob(jwt.split(".")[1]));
  }

  static convertTokenJsonToUser(json) {
    const id = json["accID"];
    const name = json["username"];
    const role = json["role"];
    const appID = json["appID"];
    return {
      id: id ? id : 0,
      name: name ? name : null,
      role: role ? role : null,
      appID: appID ? appID : null,
    };
  }

  static getApiURL() {
    const apiURL = (window.configData && window.configData.apiURL) || null;
    if (apiURL === null) {
      throw new Error("error: configData.apiURL is not set");
    }
    return apiURL;
  }

  static getAppID() {
    const appID = (window.configData && window.configData.appID) || null;
    if (appID === null) {
      throw new Error("error: configData.appID is not set");
    }
    return Number(appID);
  }
}
