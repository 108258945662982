import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

export default class RegisterConfirmPage extends Component {
  componentDidMount() {
    if (!this.props.location.query || !this.props.location.query.alowed) {
      this.props.history.push("/login");
    }
  }
  render() {
    return (
      <div className="container container-middle container-small">
        <div className="contentWrapper">
          <Header />
          <div className="form">
            <h3 className="title"><Label name="registerConfirmationPage.title" /></h3>
            {CultureService.getLabel("settings", "registrationSuccessMsg") && (
              <h4 className="title">
                {CultureService.getLabel("settings", "registrationSuccessMsg") || CultureService.getLabel("texts", "registerConfirmationPage.thankYouMsg")}
              </h4>
            )}
            <p>
              <Label name="registerConfirmationPage.sendEmailText" />
            </p>
            <p>
              <Label name="registerConfirmationPage.activationEmailInstruction" />
            </p>
            <div className="description">
              <Label name="registerConfirmationPage.didNotReceive" />
              <ol>
                <li><Label name="registerConfirmationPage.checkSpam" /></li>
                <li>
                  <Label type="texts" name="registerConfirmationPage.contactUsByEmail" />
                  <Label type="settings" name="contactEmail" />
                </li>
              </ol>
            </div>
            <Link to="/login" className="btn">
              <Label name="registerConfirmationPage.linkToSigningPage" />
            </Link>
            <hr className="distance" />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
