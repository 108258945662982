import React, { Component } from "react";
import { AuthService } from "../../services/auth.service";
import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Label from "../../components/label";
import CultureService from "../../services/culture.service";

const initialState = {
  username: {
    value: "",
    errors: {
      required: false,
      username: false
    },
    invalid: false
  },
  error: false,
  errorMessages: [],
  disabledLogin: false
};
const USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED';

export default class RecoveryPage extends Component {
  state = {
    ...initialState
  };
  validateEmail(e) {
    const usernameVal = e.target.value;
    if (!usernameVal) {
      this.validateError(usernameVal);
      return;
    }
    this.setState(state => ({
      ...state,
      error: false,
      username: {
        ...state.username,
        errors: {
          ...state.username.errors,
          username: false
        },
        invalid: false
      }
    }));
  }
  validateError() {
    this.setState(state => ({
      ...state,
      error: true,
      username: {
        ...state.username,
        errors: {
          required: !state.username.value,
          username: true
        },
        invalid: true
      }
    }));
  }
  onSubmit = () => {
    const usernameVal = this.state.username.value;
    if (usernameVal && !this.state.error) {
      this.setState({
        disabledLogin: true
      });
      AuthService.recovery(usernameVal, data => {
        this.setState({
          disabledLogin: false
        });
        if (data.errorStatus && (data.errorStatus.status < 200 || data.errorStatus.status >= 400)) {
          switch (data.errorStatus.data.errorCode) {
            case USER_NOT_AUTHENTICATED:
              this.setState({
                errorMessages: [
                  `'${CultureService.getLabel("texts", "recoveryPage.userNameErrorFirst")}' '${this.state.username.value}' '${CultureService.getLabel("texts", "recoveryPage.userNameErrorlast")}'`
                ]
              });
              break;
            default:
              this.setState({ errorMessages: [CultureService.getLabel("texts", "recoveryPage.defaultError")] });
              break;
          }
          return;
        }
        this.props.history.push({
          pathname: "/recovery-confirmation",
          query: { alowed: true }
        });
      });
      return;
    }
    this.validateError();
  };
  handleChange = e => {
    const usernameVal = e.target.value;
    this.setState(state => ({
      username: {
        ...state.username,
        value: usernameVal
      }
    }));
  };
  render() {
    const hasError = this.state.errorMessages.map((errorMsg, index) => (
      <span key={errorMsg + index}>{errorMsg}</span>
    ));
    return (
      <div className="container container-small container-middle">
        <div>
          {this.state.disabledLogin && <div className="loaderSpinner" />}
          <div
            className="contentWrapper"
            style={{
              visibility: this.state.disabledLogin ? "hidden" : "visible"
            }}
          >
            <Header />
            <div className="form">
              <h3 className="title"><Label name="recoveryPage.title" /></h3>
              {!!hasError.length && (
                <p className="error text-center">{hasError}</p>
              )}
              <div
                className={
                  this.state.username.invalid
                    ? "form-group error"
                    : "form-group"
                }
              >
                <label htmlFor="username"><Label name="recoveryPage.userName" /></label>
                <input
                  type="text"
                  id="username"
                  value={this.state.username.value}
                  onChange={this.handleChange}
                  onBlur={e => this.validateEmail(e)}
                />
                {this.state.username.invalid && (
                  <div>
                    {this.state.username.errors.required && (
                      <span><Label name="recoveryPage.userNameRequired" /></span>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-big" />
              <button
                className="btn mb-1"
                onClick={!this.state.disabledLogin && this.onSubmit}
              >
                <Label name="recoveryPage.submit" />
              </button>
              <Link to="/login" className="btn btn-secondary">
                <Label name="recoveryPage.cancel" />
              </Link>
              <hr className="distance" />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
